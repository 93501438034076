<template>
  <div class="get-code">
    <div class="get-code__wrap">
      <span class="get-code-link" @click="toggleOpen">{{ transactionsContent?.get_code?.button }}</span>
    </div>

    <client-only>
      <vue-final-modal
        v-model="isOpen"
        class="modal-get-code"
        :clickToClose="false"
        :overlayTransition="{ mode: 'in-out', duration: 200 }"
        :contentTransition="{ mode: 'in-out', duration: 200 }"
      >
        <div class="scroll">
          <div class="header">
            <span class="get-code-title">{{ transactionsContent?.get_code?.title }}</span>
          </div>
          <div class="main">
            <div class="get-code-main">
              <span class="get-code-warning">{{ transactionsContent?.get_code?.description }}</span>
              <button-copy-id
                :btn-text="transactionsContent?.get_code?.copy"
                :code="profile?.uniqNumber"
                name="uniqueNumber"
                :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.cash_agent.copyIdLabel')"
                @copy-id="copyId"
              />
              <button-copy-id
                :btn-text="transactionsContent?.get_code?.copy"
                :code="invoice.publicData.securityCode"
                :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.cash_agent.secretCodeLabel')"
                name="secretCode"
                @copy-id="copySecretCode"
              />
            </div>
            <div class="actions">
              <button-base type="primary" class="btn-get-code" @click="toggleClose">
                <span>{{ transactionsContent?.get_code?.close }}</span>
              </button-base>
            </div>
          </div>
        </div>
      </vue-final-modal>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  import type { IBottomSheetComponent, ITransactionsHistory } from '~/types';

  defineProps<{
    invoice: any;
    transactionsContent: ITransactionsHistory;
  }>();

  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const { showAlert } = useLayoutStore();

  const { getContent } = useProjectMethods();

  const isUniqueNumberAlertShown = ref(false);
  const isSecretCodeAlertShown = ref(false);
  const isOpen = ref(false);

  const copyId = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      if (value && !isUniqueNumberAlertShown.value) {
        showAlert(
          alertsData.value?.wallet?.copyUniqueNumberSuccess ||
            defaultLocaleAlertsData.value?.wallet?.copyUniqueNumberSuccess
        );
      }
    } catch (e) {
      console.log('Error copying value:', e);
    }
  };

  const copySecretCode = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      if (value && !isSecretCodeAlertShown.value) {
        showAlert(
          alertsData.value?.wallet?.copySecretCodeSuccess ||
            defaultLocaleAlertsData.value?.wallet?.copySecretCodeSuccess
        );
      }
    } catch (e) {
      console.log('Error copying value:', e);
    }
  };
  const globalStore = useGlobalStore();
  const { alertsData, defaultLocaleAlertsData, popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
  const bottomSheet = ref<Maybe<IBottomSheetComponent>>();

  const toggleOpen = (): void => {
    isOpen.value = true;
  };
  const toggleClose = (): void => {
    bottomSheet.value!.close();
  };
</script>

<style src="~/assets/styles/components/alert/get-code.scss" lang="scss" />
